import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
import BrandMovie from '../../../components/BrandMovie';
import * as style from './vision-and-mission.css';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="ビジョンとミッション - Amebaブランド" mdxType="SEO" />
    <PageTitle title="ビジョンとミッション" enTitle="Vision and Mission" mdxType="PageTitle" />
    <BrandMovie mdxType="BrandMovie" />
    <p>{`Amebaのビジョン、ミッションについては詳しく知りたい場合は、ブランドサイトをご確認ください。`}</p>
    <h2 {...{
      "id": "visionslogan---理想とする姿",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#visionslogan---%E7%90%86%E6%83%B3%E3%81%A8%E3%81%99%E3%82%8B%E5%A7%BF",
        "aria-label": "visionslogan   理想とする姿 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Vision/Slogan - 理想とする姿`}</h2>
    <p {...{
      "className": "visionAndMission-heroImage"
    }}>{`
  `}<img parentName="p" {...{
        "src": "/images/brand/vision-and-mission.svg",
        "alt": "つくる、つむぐ、つづく、Ameba LIFE",
        "className": "theme--light",
        "height": 110,
        "width": 388
      }}></img>{`
  `}<img parentName="p" {...{
        "src": "/images/brand/vision-and-mission.svg",
        "alt": "つくる、つむぐ、つづく、Ameba LIFE",
        "className": "theme--dark theme--inverse",
        "height": 110,
        "width": 388
      }}></img>{`
`}</p>
    <h2 {...{
      "id": "our-mission---存在意義-社会的意思",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#our-mission---%E5%AD%98%E5%9C%A8%E6%84%8F%E7%BE%A9-%E7%A4%BE%E4%BC%9A%E7%9A%84%E6%84%8F%E6%80%9D",
        "aria-label": "our mission   存在意義 社会的意思 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Our Mission - 存在意義 社会的意思`}</h2>
    <p {...{
      "className": "visionAndMission-heroImage"
    }}>{`
  `}<img parentName="p" {...{
        "src": "/images/brand/our-mission.svg",
        "alt": "人と情報をつむぎ 暮らしが豊かに育ちつづけるための機会をつくる。",
        "className": "theme--light",
        "height": 109,
        "width": 388
      }}></img>{`
  `}<img parentName="p" {...{
        "src": "/images/brand/our-mission.svg",
        "alt": "人と情報をつむぎ 暮らしが豊かに育ちつづけるための機会をつくる。",
        "className": "theme--dark theme--inverse",
        "height": 109,
        "width": 388
      }}></img>{`
`}</p>
    <h2 {...{
      "id": "values---visionを実現するための価値観",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#values---vision%E3%82%92%E5%AE%9F%E7%8F%BE%E3%81%99%E3%82%8B%E3%81%9F%E3%82%81%E3%81%AE%E4%BE%A1%E5%80%A4%E8%A6%B3",
        "aria-label": "values   visionを実現するための価値観 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Values - Visionを実現するための価値観`}</h2>
    <figure>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/1262b2f9208081436a7d5475ee2e9291/21b4d/vision-value.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/1262b2f9208081436a7d5475ee2e9291/2aaaf/vision-value.webp 160w", "/static/1262b2f9208081436a7d5475ee2e9291/85e47/vision-value.webp 320w", "/static/1262b2f9208081436a7d5475ee2e9291/75198/vision-value.webp 640w", "/static/1262b2f9208081436a7d5475ee2e9291/691bc/vision-value.webp 960w", "/static/1262b2f9208081436a7d5475ee2e9291/223e5/vision-value.webp 1280w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/1262b2f9208081436a7d5475ee2e9291/69538/vision-value.png 160w", "/static/1262b2f9208081436a7d5475ee2e9291/72799/vision-value.png 320w", "/static/1262b2f9208081436a7d5475ee2e9291/6af66/vision-value.png 640w", "/static/1262b2f9208081436a7d5475ee2e9291/d9199/vision-value.png 960w", "/static/1262b2f9208081436a7d5475ee2e9291/21b4d/vision-value.png 1280w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/1262b2f9208081436a7d5475ee2e9291/6af66/vision-value.png",
                "alt": "つくる、つむぐ、つづく。Ameba LIFEの循環を説明する図",
                "title": "つくる、つむぐ、つづく。Ameba LIFEの循環を説明する図",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      <figcaption className="visually-hidden">図は、AmebaとSpindleを中心に、「つくる」と「つむぐ」のサイクルを積み重ねた結果、「つづく」に繋がるフローのイメージを表している。また、そのフローの周囲には、AmebaブログやPiggなど、Ameba関連サービスが存在する。</figcaption>
    </figure>
    <p className="visionAndMission-centeringImage">
  <img src="/images/brand/vision-tsukuru.png" alt="つくる" height="260" width="266" />
    </p>
    <p>{`何かをはじめる、生み出す、発信する。
ユーザーの「つくりたい」を叶える機会を提供する。`}</p>
    <p className="visionAndMission-centeringImage">
  <img src="/images/brand/vision-tsumugu.png" alt="つむぐ" height="252" width="258" />
    </p>
    <p>{`新しい人や情報に触れ、交流し、刺激を受ける。
これまでAmebaに集まった想いをつなぎ、発展させる。`}</p>
    <p className="visionAndMission-centeringImage">
  <img src="/images/brand/vision-tsuduku.png" alt="つづく" height="255" width="266" />
    </p>
    <p>{`「つくる」と「つむぐ」を積み重ね、成長しつづける。
変化と成長をつづけ、時代を超えるサービスを育てる。`}</p>
    <h2 {...{
      "id": "statement---ブランドの思想",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#statement---%E3%83%96%E3%83%A9%E3%83%B3%E3%83%89%E3%81%AE%E6%80%9D%E6%83%B3",
        "aria-label": "statement   ブランドの思想 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Statement - ブランドの思想`}</h2>
    <p>{`「つくる」とは、はじめること。`}<br />{`
「つむぐ」とは、つながり、新しさに出会うこと。`}<br />{`
「つづく」とは、成長し生きていくこと。`}</p>
    <p>{`6400万人以上と向き合ってきた`}<br />{`
Amebaが思う、暮らしの3つの価値。`}</p>
    {
      /* textlint-disable @openameba/ameba/no-doubled-joshi */
    }
    <p>{`時間も場所もこえて、人と情報をつなぎ`}<br />{`
あらゆる暮らしが豊かに育ちつづけていく。`}</p>
    {
      /* textlint-enable */
    }
    <p>{`Amebaはこれからも、さまざまな場所や機会を通して`}<br />{`
この価値を、提供しつづけていきます。`}</p>
    <p>{`つくる、つむぐ、つづく、`}<br />{`
Ameba LIFE`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      